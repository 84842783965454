
const capsFirstLetter = (str) => {
   return str?.charAt(0).toUpperCase() + str?.slice(1);
};

function LogOut(PATH) {
   localStorage.clear(); // Clear expiry time on logout
   window.location.href = PATH;
}

function checkLogout() {
    const expiresIn = localStorage.getItem('expires_in');
    if (expiresIn) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (currentTime >= parseInt(expiresIn)) {
            LogOut(); // Logout if expiry time has passed
        }
    }
}

// Call checkLogout periodically, for example every hour
setInterval(checkLogout, 1000 * 60 * 60); // Check every hour


export { capsFirstLetter, LogOut }