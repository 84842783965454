import {Link } from 'react-router-dom';
import { BiWorld } from 'react-icons/bi';
import { FaRegUser } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { TbLogout } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import MessageBox from '../../../components/MessageBox';
import { RxHamburgerMenu } from 'react-icons/rx';
import { toast } from 'react-toastify';
import { LogOut, capsFirstLetter } from '../../Pages/CommonMethods/CommonMethods';

const AdvertiserHeader = ({ toggleAction }) => {
  let userName, advertiserName;
  
  if (localStorage.getItem("username")) {
    userName = localStorage.getItem("username");
  } else {
    advertiserName = localStorage.getItem("name");
  }
  const capitalizedUsername = userName ? capsFirstLetter(userName) : capsFirstLetter(advertiserName);

  const toggleAdvertiseNavbar = () => { toggleAction() };

  const handleLogout = () => {
    LogOut('/login');
    toast.success("Logged out successfully");
  };

  return (
    <nav className="theme-bg-color  container-fluid py-2 py-sm-3 advertiserHeader">
      <ul className="d-flex align-items-center justify-content-between p-0 m-0 list-unstyled">
        <li>
          <button type="button" onClick={toggleAdvertiseNavbar} className="bg-transparent d-inline-flex p-2 toggleAdminButton">
            <RxHamburgerMenu className="adminIcon" />
          </button>
        </li>
        <li>
          <div className="d-flex align-items-center justify-content-center gap-3">
            <Link className="btn btn-primary rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2 next-submit" to={`${process.env.REACT_APP_Baseurl_Frontend}`} > 
              <BiWorld className="adminIcon me-2 mt-1" /> <span className="d-none d-sm-block">Website</span>
            </Link>
          </div>
        </li>
        <li className="nav-item mb-2">
          <div className="nav-link d-flex align-items-center justify-content-between sideMenu position-relative collapsed"
            data-bs-toggle="collapse" data-bs-target="#AccountDetails" aria-expanded="false" aria-controls="AccountDetails" >
            <div>
            <FaRegUser fill='currentColor' className="z-index-for-channel position-relative" />
              <MessageBox text={capitalizedUsername} classname="ms-2 theme-text-color text-break" />
            </div>
            <IoIosArrowDown />
          </div>

          <div className="userListBox nav-content collapse p-0 mt-2 mb-0" id="AccountDetails" data-bs-parent="#sidebar-nav">
            <div className="theme-bg-color p-3 shadowHeaderMenu rounded-2">
              <Link className="headerSubLink d-block mt-2 " to="/account_detail" aria-label={"My Profile"} > 
              <CgProfile /> My Profile 
              </Link>
              <div className='theme-border-bottom-secondary mt-3'></div>
              <Link className="headerSubLink d-block mt-3" aria-label={"Logout"} onClick={handleLogout} >
                <TbLogout /> Logout 
              </Link>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default AdvertiserHeader