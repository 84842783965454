import React, { useState, useEffect } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "../Styles/ScheduleAdvertisement.css";

const ScheduleAdvertisement = (props) => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [surgePrice, setSurgePrice] = useState("");
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDate(null);
    setSurgePrice("");
  };

  const handleDateClick = (info) => {
    setSelectedDate(info.date);
    setShowModal(true);
  };

  const handleSurgePriceChange = (e) => {
    setSurgePrice(e.target.value);
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/advertiser/schedule-advertisement`,
        { headers }
      );
      const result = response.data;
      console.log("result", result);
  
      if (result.status) {
        const formattedEvents = result.adsEvents.map((event) => {
          // Parse the start and end timestamps into Date objects
          const startDate = new Date(event.start_timestamp);
          const endDate = new Date(event.end_timestamp);
  
          // Extract the date part and format it (FullCalendar requires ISO date)
          const startDateISO = startDate.toISOString().split("T")[0]; // YYYY-MM-DD
          const endDateISO = endDate.toISOString().split("T")[0]; // YYYY-MM-DD
  
          // Convert 24-hour time format to 12-hour format with AM/PM
          const startTime = startDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
          const endTime = endDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
  
          // Convert to FullCalendar format (start and end are in ISO string format, including time)
          const startISO = new Date(event.start_timestamp).toISOString();  // FullCalendar requires ISO string
          const endISO = new Date(event.end_timestamp).toISOString();      // FullCalendar requires ISO string
  
          console.log("startDateISO & endDateISO", startDateISO, endDateISO);
          console.log("startTime & endTime", startTime, endTime);
  
          return {
            id: event.id,
            title: `Ad ${event.ads_id} | ${startTime} - ${endTime}`,
            start: startISO,   // FullCalendar requires ISO string format
            end: endISO,       // FullCalendar requires ISO string format
            extendedProps: {
              advertiserId: event.advertiser_id,
              adsId: event.ads_id,
              status: event.status,
            },
          };
        });
        setEvents(formattedEvents); // Set events dynamically
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };   

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDeleteEvent = (eventId) => {
    fetch(`/api/delete-event/${eventId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          setEvents((prevEvents) =>
            prevEvents.filter((event) => event.id !== eventId)
          );
        } else {
          console.error("Failed to delete event");
        }
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowDeleteModal(false);
      });
  };

  const handleEditEvent = (eventId) => {
    fetch(`/api/get-event/${eventId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch event details");
        }
      })
      .then((data) => {
        setSelectedEvent(data);
        setShowEditModal(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUpdateEvent = (updatedEvent) => {
    fetch(`/api/update-event/${updatedEvent.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedEvent),
    })
      .then((response) => {
        if (response.ok) {
          setEvents((prevEvents) =>
            prevEvents.map((event) =>
              event.id === updatedEvent.id ? updatedEvent : event
            )
          );
        } else {
          console.error("Failed to update event");
        }
        setShowEditModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowEditModal(false);
      });
  };

  const openEditModal = (eventId) => {
    setSelectedEventId(eventId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEventId(null);
  };

  return (
    <>
      <div className="m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-12 col-sm-12 col-lg-12">
                <h3>Calendar Events</h3>
              </div>
            </div>
            <div className="row col-lg-12 ">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="card p-3">
                  <div className="App">
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      events={events}
                      dateClick={handleDateClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ScheduleAdvertisement;