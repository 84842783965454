import React, { useState, useEffect } from "react";
import { TbPlus } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import Response_Loading_processing from "../../layout/Others/Response_Loading_processing";

const AdvertisementEditTimeSlot = ({ data = {}, ...props }) => {
   const [weeklyHours, setWeeklyHours] = useState({
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
   });

   const access_token = localStorage.getItem("access_token");
   const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
   };

   const [processing, setProcessing] = useState(false);
   const [showOverlay, setShowOverlay] = useState(false);

   // Fetch and set initial data into weeklyHours state
   const fetchData = () => {
      const completeData = {
         Monday: data.Monday || [],
         Tuesday: data.Tuesday || [],
         Wednesday: data.Wednesday || [],
         Thursday: data.Thursday || [],
         Friday: data.Friday || [],
         Saturday: data.Saturday || [],
         Sunday: data.Sunday || [],
      };
      setWeeklyHours(completeData);
   };

   useEffect(() => {
      const savedWeeklyHours = localStorage.getItem("weeklyHours");
      if (savedWeeklyHours) {
         setWeeklyHours(JSON.parse(savedWeeklyHours));
      } else {
         fetchData();
      }
   }, [data]);

   // Add a new time slot to the specified day
   const addTimeSlot = (day) => {
      const newSlot = { id: Date.now(), start_time: "", end_time: "" };

      setWeeklyHours((prev) => {
         const updatedWeeklyHours = {
            ...prev,
            [day]: [...prev[day], newSlot],
         };
         localStorage.setItem("weeklyHours", JSON.stringify(updatedWeeklyHours));
         return updatedWeeklyHours;
      });
   };

   // Update a time slot (start time or end time)
   const updateTimeSlot = (day, index, field, value) => {
      setWeeklyHours((prev) => {
         const updatedDay = prev[day].map((slot, i) =>
            i === index ? { ...slot, [field]: value } : slot
         );
         const updatedWeeklyHours = { ...prev, [day]: updatedDay };
         localStorage.setItem("weeklyHours", JSON.stringify(updatedWeeklyHours));
         return updatedWeeklyHours;
      });
   };

   // Delete a time slot from a specific day
   const deleteTimeSlot = (day, index) => {
      setWeeklyHours((prev) => {
         const updatedWeeklyHours = {
            ...prev,
            [day]: prev[day].filter((_, i) => i !== index),
         };
         localStorage.setItem("weeklyHours", JSON.stringify(updatedWeeklyHours));
         return updatedWeeklyHours;
      });
   };

   return (
      <>
         <div>
            {showOverlay && (
               <div className="overlay-container">
                  <span className="overlay-icon">
                     <Response_Loading_processing />
                     Processing...
                  </span>
               </div>
            )}
         </div>

         <section className="card p-2">
            <div className="container">
               <h4>Ads Time Slot</h4>
               <hr />
               <h5>Set your weekly hours</h5>
               {Object.keys(weeklyHours).map((day) => (
                  <div key={day} className="mb-4">
                     <div className="d-flex align-items-center">
                        <h6>{day}</h6>
                        <button className="btn btn-link" onClick={() => addTimeSlot(day)}>
                           <TbPlus />
                        </button>
                     </div>
                     {weeklyHours[day].length === 0 ? (
                        <p className="text-muted">No time slots added yet.</p>
                     ) : (
                        weeklyHours[day].map((slot, index) => (
                           <div className="row mb-2" key={slot.id}>
                              <div className="col-md-3">
                                 <input
                                    type="time"
                                    className="form-control"
                                    value={slot.start_time}
                                    onChange={(e) =>
                                       updateTimeSlot(day, index, "start_time", e.target.value)
                                    }
                                 />
                              </div>
                              <div className="col-md-3">
                                 <input
                                    type="time"
                                    className="form-control"
                                    value={slot.end_time}
                                    onChange={(e) =>
                                       updateTimeSlot(day, index, "end_time", e.target.value)
                                    }
                                 />
                              </div>
                              <div className="col-md-3">
                                 <button
                                    className="btn btn-danger"
                                    onClick={() => deleteTimeSlot(day, index)}
                                 >
                                    <RiDeleteBinLine />
                                 </button>
                              </div>
                           </div>
                        ))
                     )}
                  </div>
               ))}
            </div>
         </section>
      </>
   );
};

export default AdvertisementEditTimeSlot;