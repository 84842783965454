import React from 'react'

const PageLoader = ({ className }) => {
    return (
        <div className={`${className ? className : "d-flex justify-content-center align-items-center paddingTop min-vh-100"}`}>
            <div className="loader"></div>
        </div>
    );
};

export default PageLoader;
