import ResponseLoader from '../../../components/Loader/ResponseLoader';
import React from 'react';

const NoRecordTable = ({ result, loader }) => {
    return (
        <div className="noRecord">
            {loader ? (
                <label> <ResponseLoader></ResponseLoader> Loading... </label>) :
                (result <= 0 && (<p className=" theme-text-color"> No data Available!  </p>))}
        </div>
    )
}

export default NoRecordTable;