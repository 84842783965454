import React, { useState, useEffect } from 'react';
import Navbar from "../../../components/Header";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../Styles/signin.css'
import ButtonWithLoader from '../../Pages/CommonComponents/ButtonWithLoader';
import google from '../../../assets/icons/google-icon.webp';
import fb from '../../../assets/icons/facebook-icon.webp';
import { toast } from 'react-toastify';

const AdvertiserLogIn = () => {
  const navigate = useNavigate();
  const prevLocation = localStorage.getItem('location');
  const [inputField, setInputField] = useState({ email: "", password: "" });
  const [errField, setErrField] = useState({ emailerr: "", passworderr: "" });
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);

  // Handle input changes
  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const login = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Baseurl}/advertiser/login`,
        { email_id: inputField?.email, password: inputField?.password }, { cors: "no-cors" },{ credentials: "include" }, 
        { credentials: "same-origin" }, {headers}, 
      );

      const { token, advertiser } = res.data.data;
      localStorage.setItem("access_token", token);
      localStorage.setItem("id", advertiser.id);
      localStorage.setItem("name", advertiser.company_name);

     
      navigate(prevLocation || '/ads-list');
      toast.success("Logged in successfully");

    } catch (err) {
      setErrField((prevState) => ({
        ...prevState,
        passworderr: "Invalid email or password.",
      }));
      console.error("Login error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Validate form inputs
  const validForm = (e) => {
    e.preventDefault();

    const validateEmail = (email) =>
      String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    let formIsValid = true;
    setErrField({ emailerr: "", passworderr: "" });

    if (!inputField?.email) {
      formIsValid = false;
      setErrField((prev) => ({ ...prev, emailerr: "Please Enter Email*" }));
    } else if (!validateEmail(inputField?.email)) {
      formIsValid = false;
      setErrField((prev) => ({ ...prev, emailerr: "Invalid Email ID" }));
    }

    if (!inputField?.password) {
      formIsValid = false;
      setErrField((prev) => ({ ...prev, passworderr: "Please Enter Password*" }));
    }

    if (formIsValid) login();
  };

  const togglePassword = () => {
    setPasswordType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) 
      navigate("/ads-list");
  },[]);

  return (
    <div>
      {/* <Navbar /> */}
      <form onSubmit={validForm}>
        <section className="signin h-100 p-5">
          <div className="container">
            <div className="d-flex justify-content-center text-center">
              <div className="box-signin p-5">
                <h1 className="text-center mt-3">Advertiser Log In</h1>
                <div>
                  <input id="email" name="email" className="mt-4 w-100 p-2"
                    value={inputField?.email} placeholder="Enter your Email"
                    type="email" onChange={handleChange} autoComplete="off" />
                  {errField.emailerr && <span className="loginerror">{errField.emailerr}</span>}
                </div>
                <div>
                  <span className="eyepasswordsignin" aria-hidden="true" onClick={togglePassword}>
                    {passwordType === "password" ? ( <i className="fa fa-eye-slash"></i> ) : ( <i className="fa fa-eye"></i> )}
                  </span>
                  <input id="password" className="mt-4 w-100 p-2" name="password"
                    value={inputField?.password} type={passwordType} onChange={handleChange}
                    placeholder="Enter Your Password" autoComplete="off" />
                  {errField.passworderr && <span className="loginerror">{errField.passworderr}</span>}
                </div>

                <button type="submit" className="btn btn mt-4 w-100 button" disabled={loading} >
                  {loading ? "Loading..." : "Sign In"}
                </button>

                {/* <Link to="">
                  <h6 className="forgot mt-3">Forgot Password?</h6>
                </Link> */}
                {/* <div className="col-sm-2 col-12 p-0 pe-2 d-flex justify-content-sm-between justify-content-center align-items-center gap-2">
                  <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                  <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                </div> */}
                <div className="col-5 d-none d-sm-flex  justify-content-center" >
                  <span className="code"></span>
                </div>
                <div className='mt-4'>
                  <h6 className="accountsign">
                    Don't have an account?{" "}
                    <Link to="/signup" className="text-decoration-none">
                      <span>Sign Up</span>
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default AdvertiserLogIn;