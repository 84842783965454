import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import ResponseLoader from "../../components/Loader/ResponseLoader";
import AdvertiserPanel from "./AdvertiserPanel";
import AdminSliderModal from "./Modal/AdminSliderModal";
import AdminDeleteModal from "./Modal/AdminDeleteModal";
import AdminHeader from "./Layout/AdminHeader";
import AdminNavbar from "./Layout/AdminNavbar";
import NoRecordTable from "./Layout/NoRecordTable";
import { useTranslation } from "react-i18next";
import { getItemToken } from "../../Utils/localStorageUtils";
import { capsFirstLetter } from "../Pages/CommonMethods/CommonMethods";
import { ActionFormatterComponent, statusFormatter } from "../Pages/CommonMethods/CommonBoostrapFormatter";

function AdvertisementList() {
    const { t } = useTranslation();
    const [apiData, setApiData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [modelClose, setModelClose] = useState(false);
    const [itemToDelete, setItemToDelete] = useState([]);
    const [withOutRefresh, setWithoutRefresh] = useState(false);
    const userAccessToken = getItemToken("access_token");

    const columns = [
        { dataField: "ads_name", text: "Ads Name", sort: true },
        { dataField: "ads_category", text: "Ads Category" },
        { dataField: "ads_position", text: "Ads Position" },
        { dataField: "location", text: "Location" },
        // { dataField: "views", text: "Views" },
        { dataField: "status", text: "Status", formatter: (cell, row) => statusFormatter(cell, row), },
        { dataField: "ACTIONS", text: "Actions", formatter: (cell, row) => ( <ActionFormatterComponent cell={cell} row={row} link={"/ads-edit/"} action={singleDelete} /> ), },
    ];

    const options = {
        pageStartIndex: 1,
        paginationSize: 4,
        sizePerPage: 10,
        nextPageText: "Next",
        prePageText: "Prev",
        firstPageText: <MdKeyboardDoubleArrowLeft />,
        lastPageText: <MdKeyboardDoubleArrowRight />,
        showTotal: true,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "All", value: Number(filteredData?.length || 0) },
        ],
    };

    const selectRow = {
        mode: "checkbox",
        onSelect: (row, isSelect) => {
            if (row) {
                if (isSelect) {
                    setItemToDelete([...itemToDelete, row.id]);
                } else {
                    setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
                }
            }
        },
        style: { width: "30px" },
        selected: itemToDelete,
        selectionHeaderRenderer: ({ checked }) => (
            <input type="checkbox" checked={checked} onChange={handleAll} />
        ),
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/advertiser/advertisement`,
                { headers: userAccessToken}
            );
            const result = response?.data?.advertisements;
            const updatedList = result.map((item) => ({
                ...item,
                ads_category: capsFirstLetter(item?.ads_category_name || ""),
                ads_position: capsFirstLetter(item?.ads_position || ""),
                location: capsFirstLetter(item?.location || ""),
                status: item?.status == 1 ? "Published" : "Unpublished",
            }));
            setApiData(updatedList);
            setFilteredData(updatedList);
        } catch (error) {
            console.error("Error fetching data:", error);
            setApiData([]);
            setFilteredData([]);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmDelete = async () => {
        setShowOverlay(true);
        try {
            for (const id of itemToDelete) {
                const response = await axios.delete(
                    `${process.env.REACT_APP_Baseurl}/advertiser/advertisement-delete/${id}`,
                    { headers: userAccessToken }
                );
            }
            toast.success("Selected advertisements deleted successfully");
            setWithoutRefresh(!withOutRefresh);
        } catch (error) {
            toast.error(error.message || "Error deleting advertisement(s)");
        } finally {
            setShowOverlay(false);
            setItemToDelete([]);
        }
    };
    

    const handleToggle = (item) => {
        setCurrentItem(item);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModelClose(false);
    };

    const singleDelete = (id) => {
        setModelClose(true);
        setItemToDelete([id]);
    };

    const handleDeletedata = () => {
        handleConfirmDelete();
        closeModal();
    };

    const handleAll = () => {
        setSelectAll(!selectAll);
        setItemToDelete(selectAll ? [] : filteredData.map((item) => item.id));
    };

    const handleFilterChange = (data) => {
        setFilteredData(data);
    };

    useEffect(() => {
        fetchData();
    }, [withOutRefresh]);

    return (
        <AdvertiserPanel>
            {showModal && (
                <AdminSliderModal
                    modelClose={showModal}
                    sliderClose={closeModal}
                    sliderAction={handleConfirmDelete}
                />
            )}
            {modelClose && (
                <AdminDeleteModal
                    modelClose={modelClose}
                    deleteClose={closeModal}
                    deleteAction={handleDeletedata}
                />
            )}
            {showOverlay && <ResponseLoader />}
            <AdminNavbar
                heading="All Advertisements"
                totalDataLength={apiData?.length || 0}
                deleteCount={itemToDelete?.length || 0}
                navigation={`/ads-create`}
                deleteMethod={handleConfirmDelete}
                navText="Add New Ads"
            />
            <div className="theme-bg-color p-3 rounded-2 bootstrapTable multi-delete-width">
                <AdminHeader
                    searchMethod={handleFilterChange}
                    reduceData={filteredData}
                    originalData={apiData}
                    fileName={"ADS-LIST"}
                />
                <BootstrapTable
                    keyField="id"
                    pagination={paginationFactory(options)}
                    columns={columns}
                    data={filteredData || []}
                    className="no-border-table theme-text-color"
                    noDataIndication={ <NoRecordTable result={filteredData?.length || 0} loader={loading} /> }
                    hover
                    headerClasses="videoWidth"
                    selectRow={selectRow}
                    selectAll={selectAll}
                />
            </div>
        </AdvertiserPanel>
    );
}

export default AdvertisementList;