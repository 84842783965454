import React, { useState } from "react";
import Navbar from "../../../components/Header";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import ButtonWithLoader from '../../Pages/CommonComponents/ButtonWithLoader';
import google from '../../../assets/icons/google-icon.webp';
import fb from '../../../assets/icons/facebook-icon.webp';

import "react-toastify/dist/ReactToastify.css";

const AdvertiserSignUp = () => {
   const [inputField, setInputField] = useState({
      email: "",
      password: "",
      name: "",
      license_number: "",
      ccode: "",
      mobile: "",
      address: "",
   });
   const [errField, setErrField] = useState({
      emailerr: "",
      passworderr: "",
      usernameerr: "",
   });
   const [phoneNumber, setPhoneNumber] = useState("");
   const [loading, setLoading] = useState(false);
   const { t } = useTranslation();
   const navigate = useNavigate();
   const prevLocation = localStorage.getItem('location');

   const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
   };

   function handleChange(e) {
      setInputField({ ...inputField, [e.target.name]: e.target.value });
   }

   const [passwordType, setPasswordType] = useState("password");

   const togglePassword = () => {
      setPasswordType((prevType) => (prevType === "password" ? "text" : "password"));
   };

   const handleOnChange = (value, country) => {
      setPhoneNumber(value);
      setInputField({
         ...inputField,
         ccode: country.dialCode,
         mobile: value.slice(country.dialCode.length),
      });
   };

   const formData = {
      email_id: inputField.email,
      password: inputField.password,
      company_name: inputField.name,
      license_number: inputField.license_number,
      ccode: inputField.ccode,
      mobile_number: inputField.mobile,
      address: inputField.address,
   };

   async function signup() {
      try {
         const response = await axios.post(`${process.env.REACT_APP_Baseurl}/advertiser/signup`, formData, { headers });
         const result = response?.data;
         if (result?.status) {
            toast.success(t(result?.message));
            setLoading(false);
         } else {
            toast.error(`The email has already been taken. ${t(result?.message)}`);
            setLoading(false);
         }
         setTimeout(() => {
            navigate(prevLocation || '/');
         }, 300);
      } catch (error) {
         const errorMessage = error.response.data ? error.response.data.errors[0].msg : "";
         toast.error(errorMessage);
         setLoading(false);
      }
   }

   const validateForm = (e) => {
      e.preventDefault();

      const validateEmail = (email) =>
         String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      let formIsValid = true;
      setErrField({ emailerr: "", passworderr: "" });

      if (inputField?.email === "") {
         formIsValid = false;
         setErrField((prev) => ({ ...prev, emailerr: "Please Enter Email*" }));
      } else if (!validateEmail(inputField?.email)) {
         formIsValid = false;
         setErrField((prev) => ({
            ...prev,
            emailerr: "Please Enter Valid Email ID",
         }));
      }

      if (inputField?.password === "") {
         formIsValid = false;
         setErrField((prev) => ({
            ...prev,
            passworderr: "Please Enter Password*",
         }));
      }

      if (formIsValid) {
         signup();
      }
      return formIsValid;
   };

   return (
      <div>
         {/* <Navbar /> */}
         <form onSubmit={validateForm}>
            <section className="signup h-100 p-5">
               <div className="container">
                  <div className="d-flex justify-content-center text-center">
                     <div className="box-signin p-5">
                        <h1 className="text-center mt-3">Advertiser Sign Up</h1>

                        <div>
                           {/* Advertiser-Name */}
                           <div className="position-relative pb-4">
                              <div className="border-bottom">
                                 <input
                                    className="mt-4 w-100 p-2"
                                    id="company_name"
                                    type="text"
                                    name="name"
                                    value={inputField?.name}
                                    placeholder="Enter Advertiser Name"
                                    autoComplete="off"
                                    onChange={handleChange}
                                 />
                              </div>
                              {errField?.usernameerr !== "" && (
                                 <span className="d-flex text-danger signin-error py-2 position-absolute">{errField?.usernameerr}</span>
                              )}
                           </div>
                        </div>

                        <div>
                           {/* Email */}
                           <div className="position-relative pb-4 mb-3">
                              <div className="border-bottom">
                                 <input
                                    className="w-100 p-2"
                                    id="signupemail"
                                    type="email"
                                    name="email"
                                    value={inputField?.email}
                                    placeholder="Enter email id"
                                    autoComplete="off"
                                    onChange={handleChange}
                                 />
                              </div>
                              {errField?.emailerr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute">{errField?.emailerr}</span>)}
                           </div>
                        </div>

                        <div>
                           {/* Mobile Number */}
                           <PhoneInput country={"us"} value={phoneNumber} onChange={handleOnChange} inputStyle={{ width: "100%" }}
                              placeholder="Enter your phone number" />
                        </div>

                        <div>
                           {/* License Number */}
                           <input className="mt-4 w-100 p-2" id="license_number" name="license_number" placeholder="Enter License Number" type="number"
                              value={inputField.license_number} onChange={handleChange} />
                        </div>

                        <div>
                           {/* Address */}
                           <input className="mt-4 w-100 p-2" id="address" name="address" placeholder="Enter Address" type="text"
                              value={inputField.address} onChange={handleChange} />
                        </div>

                        <div>
                           {/* Password */}

                           <div className="position-relative pb-4 mb-3">
                              <div className="d-flex">
                                 <input
                                    id="signuppassword"
                                    type={passwordType}
                                    name="password"
                                    value={inputField?.password}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    className="mt-4 w-100 p-2"
                                    placeholder='Enter your Password'
                                 />
                                 <button onClick={togglePassword} type="button" className="mt-4 p-2 eyeButton">
                                    {passwordType === "password" ?
                                       <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> :
                                       <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}
                                 </button>
                              </div>
                              {errField?.passworderr !== "" && (
                                 <span className="d-flex text-danger signin-error py-2 position-absolute">{errField?.passworderr}</span>
                              )}
                           </div>
                        </div>

                        <div>
                           <div className="mb-3 mb-sm-0 col-12 position-relative">
                              <ButtonWithLoader action={validateForm} text="Sign Up" type={"submit"} className='update-button btn btn w-100 button' />
                           </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-start flex-wrap-reverse  my-3 ">
                           {/* <div className="col-sm-2 col-12 p-0 pe-2 d-flex justify-content-sm-between justify-content-center align-items-center gap-2">
                              <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                              <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                           </div> */}
                           <div className="col-5 d-none d-sm-flex  justify-content-center" >
                              <span className="code"></span>
                           </div>
                        </div>
                        <div className="d-flex justify-content-center mt-1">
                           <h6 className="accountsign">
                              Already have an account?{" "}
                              <Link to="/login" className="text-decoration-none">
                                 <span> Log In</span>
                              </Link>
                           </h6>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </form>
      </div>
   );
};

export default AdvertiserSignUp;