import React, { useState, useEffect } from "react";
import { TbPlus } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import Response_Loading_processing from "../../layout/Others/Response_Loading_processing";

const AdvertisementTimeSlot = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [weeklyHours, setWeeklyHours] = useState([]);

  useEffect(() => {
    const savedWeeklyHours = JSON.parse(localStorage.getItem("weeklyHours"));
    if (savedWeeklyHours) {
      setWeeklyHours(savedWeeklyHours);
    }
  }, []);

  const addTimeSlot = (day) => {
    const newSlot = { id: Date.now(), day, start_time: "", end_time: "" };
    setWeeklyHours((prev) => {
      const updatedWeeklyHours = [...prev, newSlot];
      localStorage.setItem("weeklyHours", JSON.stringify(updatedWeeklyHours));
      return updatedWeeklyHours;
    });
  };

  const updateTimeSlot = (id, field, value) => {
    const updatedSlots = weeklyHours.map((slot) =>
      slot.id === id ? { ...slot, [field]: value } : slot
    );
    setWeeklyHours(updatedSlots);
    localStorage.setItem("weeklyHours", JSON.stringify(updatedSlots));
  };

  const deleteTimeSlot = (id) => {
    const updatedWeeklyHours = weeklyHours.filter((slot) => slot.id !== id);
    setWeeklyHours(updatedWeeklyHours);
    localStorage.setItem("weeklyHours", JSON.stringify(updatedWeeklyHours));
  };

  return (
    <>
      <div>
        {showOverlay && (
          <div className="overlay-container">
            <span className="overlay-icon">
              <Response_Loading_processing />
              Processing ...
            </span>
          </div>
        )}
      </div>

      <section className="card p-2">
        <div className="container">
          <h4>Ads Time Slot</h4>
          <hr />
          <h5>Set your weekly hours</h5>

          {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
            <div key={day} className="mb-4">
              <div className="d-flex align-items-center">
                <h6>{day}</h6>
                <button className="btn btn-link" onClick={() => addTimeSlot(day)}>
                  <TbPlus />
                </button>
              </div>
  
              {weeklyHours.filter((slot) => slot.day === day).length === 0 ? (
                <p className="text-muted">No time slots added yet.</p>
              ) : (
                weeklyHours
                  .filter((slot) => slot.day === day)
                  .map((slot) => (
                    <div className="row mb-2" key={slot.id}>
                      <div className="col-md-3">
                        <input
                          type="time"
                          className="form-control"
                          value={slot.start_time}
                          onChange={(e) =>
                            updateTimeSlot(slot.id, "start_time", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="time"
                          className="form-control"
                          value={slot.end_time}
                          onChange={(e) =>
                            updateTimeSlot(slot.id, "end_time", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteTimeSlot(slot.id)}
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    </div>
                  ))
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AdvertisementTimeSlot;