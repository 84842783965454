import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import AdvertiserPanel from "./AdvertiserPanel";
import AdminNavbar from "./Layout/AdminNavbar";
import "../Styles/Advertiser.css";
import Select from "react-select";
import MessageHeading from "../Pages/CommonComponents/MessageHeading";
import MessageBox from "../../components/MessageBox";
import AdvertisementTimeSlot from "../Components/AdvertisementTimeSlot";

const AdvertisementCreate = () => {
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();
  const [age, setAgeGroup] = useState([]);
  const [ageGroup, setAgeGroupList] = useState([]);
  const [gender, setGender] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [uploadType, setUploadType] = useState([]);
  const [location, setLocation] = useState([]);
  const [position, setPosition] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [advertisement_videos_upload, setAdvertisement_videos_upload] = useState("");
  const [store, setStore] = useState({
    active: 0,
    selectType: null,
    adsName: "",
    adsUrl: '',
    redirectUrl: "",
    adsFile: "",
    selectedLocation: "",
    locationName: "",
  });

  const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Access-Control-Allow-Origin": "*",
  };

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/advertiser/advertisement-create`,
        { headers: headers }
      );
      const result = response.data.data;
      setAgeGroup(result.age);
      setGender(result.gender);
      setDevices(result.devices);
      setUploadType(result.ads_upload_type);
      setPosition(result.ads_position_play);
      setCategory(result.admin_ads_category);
      setLocation(result.Country);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTimeSlot = () => {
    const weeklyHours = localStorage.getItem("weeklyHours");
  
    if (weeklyHours) {
      const parsedWeeklyHours = JSON.parse(weeklyHours);
      const currentDate = new Date().toISOString().split("T")[0];
      const schedule = parsedWeeklyHours.map((entry) => { 
        const startDateTime = new Date(`${currentDate}T${entry.start_time}:00.000Z`).toISOString();
        const endDateTime = new Date(`${currentDate}T${entry.end_time}:00.000Z`).toISOString();
        return {
          day: entry.day,
          start_timestamp: startDateTime,
          end_timestamp: endDateTime,
        };
      });
      console.log("schedule:", schedule);
      return schedule;
    }
    return [];
  };  

  const saveData = async () => {
    const schedule = handleTimeSlot();
    const formData = {
      "ads_name": store.adsName,
      "ads_redirection_url": store.redirectUrl,
      "ads_upload_type": store.selectType,
      "ads_position": positionList,
      "ads_category": categoryList,
      "ads_path": store.adsUrl ? store.adsUrl : "",
      "location": store.selectedLocation,
      "gender": genderList,
      "devices": deviceList,
      "age": ageGroup,
      schedule
    };

    try{
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/advertiser/advertisement-store`, formData, { headers: headers } );
      localStorage.removeItem("weeklyHours");

      const id = response.data.data.advertisement.id;
      if (advertisement_videos_upload){
        const formDataUpload = new FormData();
        formDataUpload.append("advertisement_videos_upload", advertisement_videos_upload);
       
        let headers = {
            "Content-Type": "multipart/form-data",
            headers: { "Access-Control-Allow-Origin": "*" },
            Authorization: "Bearer " + access_token,
        };
        await axios.patch( 
          `${process.env.REACT_APP_Baseurl}/advertiser/advertisement-upload/${id}`,
          formDataUpload, 
          { headers: headers } );
      }
      toast.success("Advertisement Stored Successfully");
      setTimeout(() => { navigate('/ads-list') }, 500); 
    } catch (error) {
        const errorMessage = error?.response?.data?.errors?.[0]?.msg || error.response.data.message || "Error saving data";
        console.log(error);
        // alert(errorMessage);
        toast.error( errorMessage);
    }

  };

  const handleSelectGender = (selectedOptions) => {
    setGenderList(selectedOptions.map((option) => option.value));
  };

  const handleSelectDevices = (selectedOptions) => {
    setDeviceList(selectedOptions.map((option) => option.value));
  };

  const handleSelectedCategory = (selectedOptions) => { 
    setCategoryList(selectedOptions.value);
  };

  const handleSelectType = (selectedOption) => {
    setStore((prev) => ({
      ...prev,
      selectType: selectedOption?.value,
    }));
  };

  const handleSelectLocation = (selectedOption) => {
    setStore((prev) => ({
      ...prev,
      selectedLocation: selectedOption.value,
      }));
  };

  const handleSelectedPosition = (selectedOption) => {
    setPositionList(selectedOption.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStore((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handlePrev = () => {
    setStore((prev) => ({
      ...prev,
      active: prev.active - 1,
    }));
  };

  const handleNext = () => {
    if (infoValidation()) {
      setStore((prev) => ({
        ...prev,
        active: prev.active + 1,
      }));
    } else if (uploadValidation()) {
      setStore((prev) => ({
        ...prev,
        active: prev.active + 1,
      }));
    } else if (locationValidation()) {
      setStore((prev) => ({
        ...prev,
        active: prev.active + 1,
      }));
    } else return null;
  };

  const infoValidation = () => {
    // return deviceList.length > 0;
    return true;
  };

  const uploadValidation = () => {
    return (
      (store.selectType !== null) &&
      (store.selectType !== "" || store.selectType == "Tag URL" && store.adsUrl !== "") &&
      (store.adsName !== "") && (categoryList == "" || categoryList != null)
    );
  };

  const locationValidation = () => {
    return (
      store.selectedLocation !== "" &&
      (store.selectedLocation === 3 ? store.locationName !== "" : true)
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    setAdvertisement_videos_upload(file);
  };

  useEffect(() => {
  }, [schedule]);
  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <AdvertiserPanel>
      <div className="p-3 rounded-3">
        <div className="theme-bg-color p-3 mt-3 rounded-2 bootstrapTable multi-delete-width">
            <AdminNavbar heading="Upload Advertisements" />        
        </div>

        {/*  <div className="theme-bg-color p-3 rounded-2 bootstrapTable multi-delete-width">
          <div className="d-flex align-items-center justify-content-between">
            <div className='col-3 p-2'>
              <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 0 ? 'header-button-bg-color' : 'header-bg-color'} `}>
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" className='uploadBoxAdvertiser' style={{ width: "50px", height: "50px" }} viewBox="0 0 100 100.5" >
                  <g>
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="80.2" y1="25.7" x2="84.3" y2="23.8" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="86.3" y1="38.6" x2="90.4" y2="36.7" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="87.8" y1="49.9" x2="92.1" y2="51.3" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="82" y1="61.8" x2="84.8" y2="65.3" />
                  </g>
                  <path style={{ fill: "currentcolor", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.2" }}  className="st1" d="M81.6,46.1c0-2.6-1-5-2.7-6.9s-4.1-3-6.7-3.2V21c0-0.4-0.3-0.7-0.7-0.7h-6.3c-0.2,0-0.4,0.1-0.5,0.2  s-0.2,0.3-0.2,0.5v5.8l-18.2,6.1h-21c-2.6,0-5.2,1-7.2,2.7s-3.3,4.1-3.7,6.7h-2.5c-1.4,0-2.7,0.7-3.4,1.9c-0.7,1.2-0.7,2.7,0,3.9  s2,1.9,3.4,1.9h2.5c0.4,2.6,1.7,5,3.7,6.7s4.6,2.7,7.2,2.7h1.6L33,84c0.1,0.3,0.4,0.6,0.7,0.5H40c0.4,0,0.7-0.3,0.7-0.7V65.3  l5.7-5.8l17.9,6v5.8c0,0.2,0.1,0.4,0.2,0.5S64.8,72,65,72h6.3c0.4,0,0.7-0.3,0.7-0.7v-15c2.6-0.2,5-1.3,6.7-3.2  C80.6,51.1,81.6,48.7,81.6,46.1L81.6,46.1z M11.8,48.5c-0.9,0-1.7-0.5-2.1-1.2s-0.4-1.7,0-2.4s1.2-1.2,2.1-1.2h2.4v0.2v4.5v0.2  L11.8,48.5z M15.6,48.3v-4.5c0-2.5,1-5,2.8-6.8s4.2-2.8,6.8-2.8h1.5v23.7h-1.5c-2.5,0-5-1-6.8-2.8C16.6,53.3,15.6,50.9,15.6,48.3  L15.6,48.3z M34.3,83.1l-5.9-23.7h16.2l-4.8,4.8h-6c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h5.6V83L34.3,83.1z M47,58.1v-5.8  c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v5.6H28.2V34.3h17.4v11.8c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-12l17.4-5.8V64L47,58.1z   M70.7,70.5h-4.8V21.7h4.8V70.5z M72.2,54.8V37.4c2.9,0.2,5.5,1.9,6.9,4.5s1.4,5.7,0,8.3S75.1,54.5,72.2,54.8L72.2,54.8z" />
                </svg>
                <MessageBox classname='advertiserSubHeading d-block' text={'Ads Info'} />
              </div>
            </div>
    
            <div className=' col-3 p-2'>
              <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 1 ? 'header-button-bg-color' : 'header-bg-color'} `}>
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100.5" className='uploadBoxAdvertiser' style={{ width: "50px", height: "50px" }}>
                  <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M75.2,61.5v16c0,3.7-3,6.7-6.7,6.7H31.5c-3.7,0-6.7-3-6.7-6.7v-15v-0.9 M55,51.5v26.9 M44.9,73.5V39.9  c0-0.8-0.6-1.5-1.3-1.6l0,0l0,0h-0.1h-0.1h-0.1h-0.1h-0.1h-5.8c-0.5,0-0.8-0.6-0.6-1l13.1-21l13.2,21.1c0.3,0.4,0,0.9-0.5,0.9h-5.9  c-0.8,0-1.5,0.6-1.6,1.4l0,0l0,0v0.1v0.1V40v0.1v6.7" />
                </svg>
                <MessageBox classname='advertiserSubHeading d-block' text={'Upload Ads'} />
              </div>
            </div>
    
            <div className=' col-3  p-2' >
              <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 2 ? 'header-button-bg-color' : 'header-bg-color'} `}>
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100.5" className='uploadBoxAdvertiser' style={{ width: "50px", height: "50px" }}>
                  <g>
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M27,38.2c0-1.8-1.5-3.3-3.3-3.3h-6.6c-1.8,0-3.3,1.5-3.3,3.3v6.6c0,1.8,1.5,3.3,3.3,3.3h6.6   c1.8,0,3.3-1.5,3.3-3.3V38.2z M73,41.5v-3.3c0-1.8,1.5-3.3,3.3-3.3h6.6c1.8,0,3.3,1.5,3.3,3.3l0,6.6 M43.1,51.2   c-1.2,0-2.2-1-2.2-2.2V35.4c0-1.2,1-2.2,2.2-2.2h13.5c1.3,0,2.3,1,2.3,2.2v6c0,0.7-0.7,1.2-1.4,1L50.6,40c-0.8-0.3-1.8-0.1-2.3,0.6   c-0.6,0.6-0.8,1.5-0.5,2.3l2.4,7c0.2,0.7-0.3,1.3-0.9,1.3L43.1,51.2z M55.3,46.5l27.2,9.3c0.8,0.3,0.8,1.4,0.1,1.7l-11.3,5   c-0.5,0.2-0.9,0.6-1.1,1.2l-5,11.2c-0.3,0.8-1.4,0.7-1.7-0.1l-9.3-27.2C53.9,46.9,54.6,46.2,55.3,46.5z" />
                  </g>
                </svg>
                <MessageBox classname='advertiserSubHeading d-block' text={'Choose Region'} />
              </div>
            </div>
    
            <div className='col-3 p-2'>
              <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 3 ? 'header-button-bg-color' : 'header-bg-color'} `}>
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100.5" className='uploadBoxAdvertiser' style={{ width: "50px", height: "50px" }} >
                  <g>
                    <g>
                      <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M61.5,39.1c-5.8,0-11.4,2.3-15.6,6.4c-4.1,4.1-6.4,9.7-6.4,15.6s2.3,11.4,6.4,15.6c4.1,4.1,9.7,6.4,15.6,6.4    c5.9,0,11.4-2.3,15.6-6.4c4.1-4.1,6.4-9.7,6.4-15.6c0-5.8-2.3-11.4-6.5-15.5C72.9,41.4,67.3,39.1,61.5,39.1L61.5,39.1z" />
                      <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M76.3,39.3V20.6c0-0.6-0.5-1.1-1.1-1.1H17.5c-0.6,0-1,0.5-1,1v50.8c0,0.6,0.5,1,1,1h22" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="29.6" y1="13" x2="29.6" y2="24.4" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="62.4" y1="13" x2="62.4" y2="24.4" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="39.7" y1="61.1" x2="43.5" y2="61.1" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="79.6" y1="61.1" x2="83.6" y2="61.1" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="61.5" y1="79.2" x2="61.5" y2="83.1" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="61.5" y1="39.1" x2="61.5" y2="43" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "1.5" }}  className="st1" x1="62" y1="62" x2="62" y2="68.8" />
                      <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "1.5" }}  className="st1" x1="65.8" y1="53.3" x2="62.4" y2="59.3" />
                    </g>
                    <circle cx="62" cy="60.4" r="1.4" style={{ fill: "none", stroke: "currentcolor" }} />
                  </g>
                </svg>
                <MessageBox classname='advertiserSubHeading d-block' text={'Ads Schedule'} />
              </div>
            </div>
          </div>
        </div> */}

        <div className="theme-bg-color p-3 mt-3 rounded-2 bootstrapTable multi-delete-width">
          {/* {store?.active === 0 ? (
            <div className="col-6">
              <MessageHeading text={"General Information"} className="advertiserHeading" />

              <MessageBox classname="advertiserSubHeading d-block my-4" text={"Age"} />
              <div className="d-flex align-items-center">
                {age.map((agegroup) => (
                  <div key={agegroup.name}>
                    <input type="checkbox" name="check" autoComplete="off"
                      checked={ageGroup.includes(agegroup.name)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAgeGroupList([...ageGroup, agegroup.name]);
                        } else {
                          setAgeGroupList(
                            ageGroup.filter((item) => item !== agegroup.name)
                          );
                        }
                      }}
                    />
                    <label className="px-2">{agegroup.value}</label>
                  </div>
                ))}
              </div>

              <MessageBox classname="advertiserSubHeading d-block my-4" text={"Gender"} />
              <Select
                options={gender?.map((option) => ({
                  value: option.name,
                  label: option.value,
                }))}
                isMulti
                value={genderList.map((value) => {
                  const match = gender.find((g) => g.name === value);
                  return { value, label: match ? match.value : value };
                })}
                className="my-3 theme-bg-color"
                onChange={handleSelectGender}
              />*/}

              {/* <MessageBox classname="advertiserSubHeading d-block my-3 before-star" text={"Advertisement Devices"}/>
              <Select
                options={devices?.map((option) => ({
                  value: option.name,
                  label: option.name,
                }))}
                isMulti
                value={deviceList.map((value) => {
                  const match = devices.find((d) => d.name === value);
                  return { value, label: match ? match.value : value };
                })}
                className="my-3 theme-bg-color"
                onChange={handleSelectDevices}
              /> */}
            {/* </div>
          ) : null} */}

          {store?.active === 0 ? (
            <div className="col-12">
              <MessageHeading text={"Advertisement Details"} className="advertiserHeading" />
              <div className="">
                <div className="d-flex flex-wrap">
                  <div className="col-6 pe-md-3">
                    <div className="card p-3">
                      {/* <MessageBox classname="advertiserSubHeading theme-text-color d-block position-relative my-3 before-star" text={"Advertisement Name"} /> */}
                      <label className="m-0 before-star">Advertisement Name</label>
                      <input type="text" name="adsName" value={store?.adsName} placeholder={"Please enter ads name"} className="rs-input form-control mt-2" onChange={handleChange} style={{ lineHeight: "2" }} />

                      {/* <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 before-star ' text={'Advertisement Category'} /> */}
                      <label className="m-0 mt-3 before-star">Advertisement Category</label>
                      <Select 
                        options={category?.map((option) => {
                          return { value: option.id, label: option.name }
                        })}
                        className="rounded-2 border-0 w-100 py-1 theme-bg-color theme-text-color mt-2"
                        onChange={handleSelectedCategory}
                      />

                      {/* <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3' text={'Advertisement Position'} /> */}
                      <label className="m-0 mt-3">Advertisement Position</label>
                      <Select
                        options={position?.filter((option) => option.value !== "all")
                          ?.map((option) => ({
                            value: option.value,
                            label: option.name,
                          }))}
                        // {position?.map((option) => {
                        //   return { value: option.value, label: option.name }
                        // })}
                        className="rounded-2 border-0 w-100 py-1 theme-bg-color theme-text-color mt-2"
                        onChange={handleSelectedPosition}
                      />
                    </div>
                  </div>

                  <div className="col-6 ps-md-3">
                    <div className="card p-3">
                      {/* <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 before-star' text={'Advertisement Upload type'} /> */}
                      <label className="m-0 before-star">Advertisement Upload type</label>
                      <Select
                        options={uploadType?.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        className="rounded-2 border-0 w-100 py-1 theme-bg-color theme-text-color mt-2"
                        onChange={handleSelectType}
                      />
                      { store.selectType !== null && store.selectType != undefined ? store.selectType == "tag_url" ? 
                        <>
                          <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3' text={'Ads url'} />
                          <input type="text" name='adsUrl' value={store?.adsUrl} onChange={handleChange} placeholder={'Paste here ads url'} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />
                        </> 
                        : 
                        <>
                          <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 ' text={'Ads Video Upload'} />
                          {/* <input type="file" onChange={handleFile} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" /> */}
                          <input type="file" className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" name="pdf_file" id="pdf_file" accept="video/*" onChange={handleFileChange} />

                          <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 ' text={'Ads redirect url'} />
                          <input type="text" name="redirectUrl" value={store?.redirectUrl} onChange={handleChange} placeholder={'https://example.com'} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />
                        </>
                      : ""}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : null}

          {/* {store?.active == 2 ? 
            <div className="col-6">
              <MessageHeading text={'Location Details'} className="advertiserHeading" />
              <Select 
                options={location?.map((option) => ({
                  value: option.name,
                  label: option.country_name,
                }))}
                className="rounded-2 border-0 w-100 py-1 theme-bg-color theme-text-color"
                onChange={handleSelectLocation}
              />
            </div>
          : ""} */}

          {store?.active == 1 ?
          <div className="col-12">
            {/* <MessageHeading text={'Set your weekly hours'} className="advertiserHeading" /> */}
            <label className="m-0">Set your weekly hours</label>
            <div className="mt-5">
              <AdvertisementTimeSlot />
            </div>
          </div>
          : ""}

        </div>

        <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
          {store?.active > 0 && (
            <button className="px-3 py-2 theme-button-bg-color rounded-2 previous" onClick={() => handlePrev()} >
              <MessageBox text="Previous" />
            </button>
          )}
          {store?.active !== 1 ? (
            <button
              className={`px-3 py-2 theme-button-bg-color rounded-2 next-submit ${
                // store?.active === 0
                //   ? !infoValidation()
                //     ? "opacity-50"
                //     : ""
                //   : 
                  store?.active === 0
                  ? !uploadValidation()
                    ? "opacity-50"
                    : ""
                  // : store?.active === 2
                  // ? !locationValidation()
                  //   ? "opacity-50"
                  //   : ""
                  : false
              }`}
              onClick={() => handleNext()}
              disabled={
                // store?.active === 0
                //   ? !infoValidation()
                //   : 
                  store?.active === 1
                  ? !uploadValidation()
                  // : store?.active === 2
                  // ? !locationValidation()
                  : false
              }
            >
              <MessageBox text="Next" />
            </button>
          ) : (
            <button className="px-3 py-2 theme-button-bg-color rounded-2 next-submit" onClick={() => saveData()} >
              Submit
            </button>
          )}
        </div>
      </div>
    </AdvertiserPanel>
  );
};

export default AdvertisementCreate;