import React from 'react';
import { MdControlPoint, MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import MessageBox from '../../../components/MessageBox';


const AdminNavbar = ({ heading, totalDataLength, deleteCount, deleteMethod, navigation, navText }) => {
    return (
        <div className="d-flex align-items-center flex-wrap">
            <div className="col-md-6 col-12 ">
                <h5 className="admin-title mb-0 theme-text-color text-md-start"> <MessageBox text={heading} classname="theme-text-color" />
                    {totalDataLength && <span className="admin-sub-title theme-text-color ms-1  ">({totalDataLength} <MessageBox text='results found' classname="theme-text-color" />)</span>}
                </h5>
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-end mt-3 mt-sm-0" >
                {deleteCount > 0 && (
                    <button className="bg-primary p-2 rounded-2 d-inline-flex align-items-center " onClick={() => deleteMethod()} style={{border: "none"}}>
                        <MdDelete className="exportIcon me-1" fill="#fff" />
                        <MessageBox text='Delete' classname='admin-button-text text-white'/>
                    </button>
                )}
                {navigation && <Link className="admin-title ms-3 rounded-2 d-inline-flex align-items-center bg-primary text-decoration-hover  py-2 px-3" to={navigation}> <MdControlPoint className="me-2 exportIcon text-white" style={{ width: "22px", height: "22px" }} /> <MessageBox classname="admin-button-text text-white" text={navText}/> </Link> }
            </div>
        </div>
    )
}

export default AdminNavbar;