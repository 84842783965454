
import { React, useState, useEffect } from "react";
import loadings from "../../assets/gif/gif_loadings.gif";
import axios from "axios"

function Response_Loading_processing() {

  const [loginData, setLoginData] = useState(null);
  const loadingGifVideo_local = localStorage.getItem("loadingGifVideo");

  useEffect(() => {
    const userData = localStorage.getItem("access_token");
    setLoginData(userData);
  }, []);

  const [loadingGifVideo, setLoadingGifVideo] = useState(null);

  useEffect(() => {
    // Retrieve access token from local storage
    const access_token = localStorage.getItem("access_token");

    // Proceed only if access_token is available
    // if (access_token) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      };

      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/index`, { headers });
          const gifVideo = response?.data?.Site_theme_setting[0]?.loading_gif_video_url;

          // Update state with the fetched data
          setLoadingGifVideo(gifVideo);

          // Set the value in localStorage
          // localStorage.setItem('loadingGifVideo', gifVideo);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Fetch data from API
      fetchData();
    // }
  }, []);

  return (
    <>
      {loginData === null ? (
        <img
          src={loadings} style={{ width: "80px", height: "80px", objectFit: "contain" }}
          className="thumbnails-images" alt="processing"
        />
      ) : (
        <img
          src={loadingGifVideo_local} style={{ width: "80px", height: "80px", objectFit: "contain" }}
          className="thumbnails-images" alt="processing"
        />
      )}
    </>
  );
}

export default Response_Loading_processing;
