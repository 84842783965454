import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import AdvertiserPanel from './AdvertiserPanel';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AdvertiserDashBoard = () => {
  const labelOne = [];
  const labelTwo = [];

  const optionOne = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cost Per Click Analysis',
      },
    },
  };

  const optionsTwo = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cost Per View Analysis',
      },
    },
  };

  const dataOne = {
    labels: labelOne,
    datasets: [
      {
        label: 'Cost Per Click',
        data: [300, 450],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(156, 204, 250)',
      },
    ],
  };

  const dataTwo = {
    labels: labelTwo,
    datasets: [
      {
        label: 'Cost Per View',
        data: [200, 300], // Example data
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(173, 253, 216)',
      },
    ],
  };

  return (
    <AdvertiserPanel>
      <div className="theme-bg-color rounded-3 p-4">
        <div className="d-flex flex-wrap">
          <div className="col-12 col-sm-12 col-md-6 p-3">
            <Bar options={optionOne} data={dataOne} />
          </div>
          <div className="col-12 col-sm-12 col-md-6 p-3">
            <Bar options={optionsTwo} data={dataTwo} />
          </div>
        </div>
      </div>
    </AdvertiserPanel>
  );
};

export default AdvertiserDashBoard;