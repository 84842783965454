// External Libraries and Packages
import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
// Import Components
import PageLoader from '../components/PageLoader';
import AdvertiserLogIn from '../AdvertiserPortal/Components/Login&Signup/AdvertiserLogIn';
import AdvertiserSignUp from '../AdvertiserPortal/Components/Login&Signup/AdvertiserSignUp';
import AdvertiserCommonLayout from "../AdvertiserPortal/Components/Layout/AdvertiserCommonLayout";
import AdvertiserDashBoard from '../AdvertiserPortal/Components/AdvertiserDashBoard';
import AdvertisementList from '../AdvertiserPortal/Components/AdvertisementList';
import AdvertiserProfile from '../AdvertiserPortal/Components/Layout/AdvertiserProfile';
import AdvertisementCreate from '../AdvertiserPortal/Components/AdvertisementCreate';
import AdvertisementEdit from '../AdvertiserPortal/Components/AdvertisementEdit';
import ScheduleAdvertisement from '../AdvertiserPortal/Components/ScheduleAdvertisement';
import HomeProtectedRoute from './HomeProtectedRoute';
import Error404 from '../components/Error404';

const AdvertiserRoutes = () => {
     const token = localStorage.getItem("access_token");
     console.log("token",token);

     return (
          <Suspense fallback={<PageLoader />}>
               <Routes>
                    <Route path='/login' element={<AdvertiserLogIn/>} />
                    <Route path='/signup' element={<AdvertiserSignUp/>} />
                    <Route path='/' element={ <HomeProtectedRoute> <AdvertiserCommonLayout /> </HomeProtectedRoute> } >
                         <Route path="/ads-dashboard" element={<AdvertiserDashBoard />} />
                         <Route path="/ads-list" element={<AdvertisementList />} />
                         <Route path="/ads-create" element={<AdvertisementCreate/>} />
                         <Route path="/ads-edit/:id" element={<AdvertisementEdit/>} />
                         <Route path="/schedule-ads" element={<ScheduleAdvertisement/>} />
                         <Route path='/account_detail' element={<AdvertiserProfile />} />
                         <Route path="*" element={<Error404/>}/>
                    </Route>
               </Routes>
          </Suspense>
     );
};

export default AdvertiserRoutes;
