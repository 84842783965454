import { Navigate } from 'react-router-dom';

const HomeProtectedRoute = ({ children }) => {
   const userData = localStorage.getItem("access_token");
     if (userData) {
       return children;
     } else {
       const loginPath = '/login';
       return <Navigate to={loginPath} />;
     }
}
 export default HomeProtectedRoute;