import '../../../Styles/AccountPage.css';
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {toast} from 'react-toastify';
import MessageBox from '../../../components/MessageBox';

const AdvertiserProfile = () => {

   const access_token = localStorage.getItem("access_token");
   const [accountdata, setAccountData] = useState([]);
   const [formDataError, setFormDataError] = useState({});
   const [loading, setLoading] = useState(true);
   const nameRef = useRef();
   const addressRef = useRef();
   const licenseRef = useRef();



   const headers = { 
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
   };

   const formValidation = () => {
      let formIsValid = true;
      if (!accountdata?.name) {
        setFormDataError((prevFormData) => ({
          ...prevFormData,
          name: "Name can't be empty",
        }));
        nameRef.current.focus();
        formIsValid = false;
      }
      return formIsValid;
   };

   const handleUpdate = async (e) => {
      if (formValidation()) {
        setLoading(true);
        let formData = new FormData();
        formData.append("company_name", accountdata?.company_name);
        formData.append("email_id", accountdata?.email_id);
        formData.append("license_number", accountdata?.license_number);
        formData.append("ccode", accountdata?.ccode);
        formData.append("mobile_number", accountdata?.mobile_number);
        formData.append("address", accountdata?.address);
  
        try {
          const response = await axios.post(`${process.env.REACT_APP_Baseurl}/advertiser/account-detail-update`, formData, { headers });
          const result = response?.data
          if (result?.status) {
            toast.success("Account Updated Successfully");
            // dispatch(profileDetailsAction());
            // setProfileImage(null)
            setLoading(false);
          }
          else { toast.error("Request failed with status: " + response?.status) }
        } catch (error) {
          const errorMessage = error?.response?.data?.message
          toast.error(errorMessage)
          setLoading(false);
        }
      }
   };

   const userDetails = async () => {
      try {
         setLoading(true);
         const response = await axios.get(`${process.env.REACT_APP_Baseurl}/advertiser/account-detail`, { headers }); 
         console.log("response",response);
         const result = await response?.data?.account_detail;
         console.log("resultt",result);
         setAccountData(result)
         setLoading(false);
      } catch (error) {
         console.error("Error fetching transaction data", error);
         setLoading(false);
      }
   };

   const handleInputChange = (event) => {
      const { company_name, value } = event.target
      console.log("event",event.target);
      setAccountData({
        ...accountdata,
        [company_name]: value,
      });
      setFormDataError((prev) => ({
        ...prev,
        [company_name]: ""
      }))
    }

   useEffect(() => {
      userDetails();
    }, []);

  return (
    <>
      <section className="account px-2 px-md-3 px-lg-5">
         <div className="container-fluid">
            <div className="account-details row m-0 d-flex justify-content-center">
               <h2 className="text-center theme-text-color py-4">My Account</h2>
               <div className="col-6 px-2 px-md-4 card p-3">
                  
                  <MessageBox text={'Advertiser Name'} classname='account-label mb-2 theme-text-color d-block' />
                  <input type="text" name="company_name" ref={nameRef} className="account-input mb-4 w-100 theme-text-color bg-transparent pe-3 py-2 bottom-border theme-border-color" value={accountdata?.company_name} onChange={handleInputChange} />
                  {formDataError?.name && (<MessageBox text={`${formDataError?.name}`} classname='errorred mb-2 d-block' />)}

                  <MessageBox text={'Email'} classname='account-label mb-2 theme-text-color d-block' />
                  <input type="email" name="email" className="account-input mb-4 w-100 theme-text-color bg-transparent pe-3 py-2 bottom-border theme-border-color" value={accountdata?.email_id} onChange={null} disabled={true} />

                  <MessageBox text={'Mobile Number'} classname='account-label mb-2 theme-text-color d-block' />
                  <input type="number" name="mobile" className="account-input mb-4 w-100 theme-text-color bg-transparent pe-3 py-2 bottom-border theme-border-color" value={accountdata?.mobile_number} onChange={handleInputChange} disabled={true} />

                  <MessageBox text={'License Number'} classname='account-label mb-2 theme-text-color d-block' />
                  <input type="number" name="license_number" ref={licenseRef} className="account-input mb-4 w-100 theme-text-color bg-transparent pe-3 py-2 bottom-border theme-border-color" value={accountdata?.license_number} onChange={handleInputChange} />
                  {formDataError?.lic_number && (<MessageBox text={`${formDataError?.lic_number}`} classname='errorred mb-2 d-block' />)}

                  <MessageBox text={'Address'} classname='account-label mb-2 theme-text-color d-block' />
                  <input type="text" name="address" ref={addressRef} className="account-input mb-4 w-100 theme-text-color bg-transparent pe-3 py-2 bottom-border theme-border-color" value={accountdata?.address} onChange={handleInputChange} />
                  {formDataError?.address && (<MessageBox text={`${formDataError?.address}`} classname='errorred mb-2 d-block' />)}

                  <button type="submit" className="update-button btn btn mt-4 button" onClick={handleUpdate} disabled={loading} >{loading ? "Loading..." : "Update Profile"} </button>
               
               </div>
            </div>
         </div>
      </section>
    </>
  );
};

export default AdvertiserProfile;