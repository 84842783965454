import React from "react";
import "../Styles/error404.css";
import errorGif from "../../src/assets/gif/error_404.gif";
function Error404() {
  return (
    <section className="w-100 h-100 d-flex align-items-center flex-column justify-content-center p-1 adminHeadContainer">
      <div className="notFoundContainer">
        <h2 className="notFoundHeading text-center theme-text-color">404</h2>
        <img src={errorGif} className="errorImage rounded-2" />
        <h2 className="text-center theme-text-color m-0">Look like you're lost</h2>
        <p className="text-center theme-text-color">The page you are looking for not available! </p>
        <div className="d-flex align-items-center justify-content-center gap-3 ">
        </div>
      </div>
    </section >
  );
}

export default (Error404);