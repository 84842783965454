import React from 'react';
import { IoClose } from 'react-icons/io5';
import AdminCustomButton from '../Button/AdminCustomButton'
import MessageBox from '../../../components/MessageBox'


const AdminDeleteModal = ({ modelClose, deleteClose, deleteAction, buttonText, message }) => {
    console.log("modelClose",modelClose);
    return (
        <div class={`modal  packageModel fade ${modelClose && 'd-block show'}`} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content  theme-bg-color ">
                    <div className="modal-header theme-border-bottom-secondary d-flex justify-content-between">
                        <MessageBox text="Confirm Deletion " classname="modal-title theme-text-color admin-modal-title fw-bold" />
                        <button type="button" className='bg-transparent' onClick={() => deleteClose()} style={{border: "none"}} ><IoClose fill='currentColor' style={{ width: "25px", height: "25px",}} /></button>
                    </div>
                    <div className="modal-body">
                        {buttonText === "custom" ? <MessageBox classname=" theme-text-color" text={message} /> :
                            buttonText === 1 ? (<MessageBox classname="theme-text-color" text="Are you sure you want to delete this item ?" />) : (<MessageBox classname=" theme-text-color" text="Are you sure you want to delete this items ?" />)}
                    </div>
                    <div className="modal-footer  border-0">
                        <button type="button" className=" px-3 py-1 btn sliderBtnText admin-button-text rounded-2" onClick={() => deleteClose()} ><MessageBox text="Cancel" /> </button>
                        <AdminCustomButton className='px-3 py-1' saveMethod={deleteAction} saveText='Delete' ></AdminCustomButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDeleteModal