import './App.css';
import AdvertiserRoutes from './Routes/AdvertiserRoutes.jsx';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <div>
        <div className='heightBody'>
          <ToastContainer position="top-right" autoClose={3000}   hideProgressBar={false} closeOnClick theme='colored' ></ToastContainer>
          <BrowserRouter>
            <AdvertiserRoutes/>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}

export default App;
