import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AdvertiserPanel from './AdvertiserPanel';
import AdminNavbar from "./Layout/AdminNavbar";
import AdvertisementEditTimeSlot from './AdvertisementEditTimeSlot';
import "../Styles/Advertiser.css";
import Select from "react-select";
import MessageBox from "../../components/MessageBox";
import Response_Processing from '../../layout/Others/Response_Loading_processing';

const AdvertisementEdit = (props) => {

   const access_token = localStorage.getItem("access_token");
   const { id } = useParams();
   const navigate = useNavigate();
   const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
   };
   const [store, setStore] = useState({
      active: 0,
   });

   const [editUser, setEditUser] = useState({
      advertiser_id: "",
      status: "",
      ads_name: "",
      ads_slug: "",
      ads_position: '',
      ads_upload_type: "",
      ads_video: "",
      ads_path: "",
      ads_category: "",
      age: "",
      devices: "",
      gender: "",
      ads_redirection_url: "",
      schedule: "",
   })
   const [editname, setEditName] = useState({
         ads_name: "",
   })
   const [formData, setFormData] = useState({});
   const [transformedAdsEvents, setTransformedAdsEvents] = useState([]);

   const [advertisement_videos_upload, setAdvertisement_videos_upload] = useState("");

      const [validationMessagetitle, setValidationMessagetitle] = useState("");
      const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

      const [validationMessageuser_ads_upload_type, setValidationMessageads_upload_type] = useState("");
      const [isValidationHiddenads_upload_type, setIsValidationHiddenads_upload_type] = useState(true);

      const [processing, setProcessing] = useState(false);
      const [showOverlay, setShowOverlay] = useState(false);

      const titleInputRef = useRef(null);
      const ads_upload_typeInputRef = useRef(null);

      const handleInput = (e) => {
         const { name, value } = e.target;
         setEditUser((prevEditUser) => ({
            ...prevEditUser,
            [name]: value,
         }));
   };

   const handleInputenable = (e) => {
         if (e.target.checked === true) {
            setEditUser({ ...editUser, [e.target.name]: 1 });
         } else {
            setEditUser({ ...editUser, [e.target.name]: 0 });
         }
   };

   const [apiResponseage, setApiResponseage] = useState([]);
   const [advertisementResponseage, setAdvertisementResponseage] = useState([]);
   const [ads_positionselect, setAds_positionselect] = useState([]);
   const [ads_categoryselect, setAds_categoryselect] = useState([]);
   const [user_ads_upload_type, setUser_ads_upload_type] = useState([]);
   const [selectedAges, setSelectedAges] = useState([]);
   
   const [optionsgender, setOptionsgender] = useState([]);
   const [selectedValuesgender, setSelectedValuesgender] = useState([]);

   const [optionsdevices, setOptionsdevices] = useState([]);
   const [selectedValuesdevices, setSelectedValuesdevices] = useState([]);

   const handleSelectChangegender = (selectedOptionsgender) => {
      // setFormData({});
      setSelectedValuesgender(selectedOptionsgender);
   };

   const handleSelectChangedevices = (selectedOptionsdevices) => {
      // setFormData({});
      setSelectedValuesdevices(selectedOptionsdevices);
   };

   useEffect(() => {
      const selectedValues = advertisementResponseage?.map((item) => item.value);
      setSelectedAges(selectedValues);
   }, [advertisementResponseage]);




   const fetchEditDetails = async () => {
      try {
         const response = await axios.get(`${process.env.REACT_APP_Baseurl}/advertiser/advertisement-edit/${id}`,
            { headers: headers }
         );

         const advertisementData = response?.data?.data?.Advertisements;

         setEditUser(response?.data?.data?.Advertisements);
         setEditName(response?.data?.data?.Advertisements);
         setApiResponseage(response?.data?.data?.age);
         
         setAds_positionselect(response?.data?.data?.ads_position_play);
         setAds_categoryselect(response?.data?.data?.admin_ads_category);
         setUser_ads_upload_type(response?.data?.data?.ads_upload_type);
         setAdvertisementResponseage(response?.data?.data?.Advertisements?.age);

         const datagender = response?.data?.data?.gender;
         const datadevices = response?.data?.data?.devices;

         const formattedOptionsgender = datagender?.map((item) => ({
            value: item?.value,
            label: item?.name,
         }));
         const formattedOptionsdevices = datadevices?.map((item) => ({
            value: item?.value,
            label: item?.name,
         }));

         setOptionsgender(formattedOptionsgender);
         setOptionsdevices(formattedOptionsdevices);

         const datagenderedit = response?.data?.data?.Advertisements?.gender;
         const datadevicesedit = response?.data?.data?.Advertisements?.devices;

         const formattedOptionsgenderedit = datagenderedit?.map((item) => ({
            value: item?.value,
            label: item?.name,
         }));
         const formattedOptionsdevicesedit = datadevicesedit?.map((item) => ({
            value: item?.value,
            label: item?.name,
         }));

         setSelectedValuesgender(formattedOptionsgenderedit);
         setSelectedValuesdevices(formattedOptionsdevicesedit);

         const transformAdsEvents = (adsEvents) => {
            const groupedEvents = {};
            adsEvents?.forEach((event) => {
                const day = event.day;
                const startTime = event.start_timestamp === "Invalid date" ? null : event.start_timestamp.split(" ")[1];
                const endTime = event.end_timestamp === "Invalid date" ? null : event.end_timestamp.split(" ")[1];

                if (!groupedEvents[day]) {
                    groupedEvents[day] = [];
                }
                groupedEvents[day].push({
                    id: event.id,
                    day: day,
                    start_time: startTime,
                    end_time: endTime,
                    createdAt: event.createdAt,
                    updatedAt: event.updatedAt,
                });
            });
            return groupedEvents;
        };

        const transformedAdsEvent = transformAdsEvents(advertisementData?.adsEvents);
        setTransformedAdsEvents(transformedAdsEvent);

      } catch (error) {
        console.log("Error fetching options:", error);
      }
   };

   const handleCheckboxChange = (value) => {
      if (selectedAges.includes(value)) {
          setSelectedAges(selectedAges.filter((age) => age !== value));
      } else {
          setSelectedAges([...selectedAges, value]);
      }
   };

   const handleTimeSlot = () => {
      const weeklyHours = JSON.parse(localStorage.getItem("weeklyHours"));    
      if (weeklyHours) {
         const transformWeeklyHours = (weeklyHours) => {
            const transformedSchedule = Object.keys(weeklyHours).map((day) => {
              if (Array.isArray(weeklyHours[day])) {
                return weeklyHours[day].map((slot) => {
                  const currentDate = new Date().toISOString().split('T')[0];
                  const startDateTime = `${currentDate} ${slot.start_time}`;
                  const endDateTime = `${currentDate} ${slot.end_time}`;
          
                  return {
                    day,
                    start_timestamp: startDateTime,
                    end_timestamp: endDateTime,
                  };
                });
              } else {
                return [];
              }
            }).flat();
            editUser.schedule = transformedSchedule;
            // console.log("editUser.schedule",editUser.schedule);
            return editUser.schedule;
         };
         const transformedData = transformWeeklyHours(weeklyHours);
      }
    
      return [];
    };
   const handleStore = async (e) => {
      e.preventDefault();
      handleTimeSlot();

      let focusInputRef = null;

      if (!editUser?.ads_name) {
          setValidationMessagetitle("Name cannot be empty.");
          setIsValidationHiddentitle(false);
          focusInputRef = titleInputRef;
      }
      if (!editUser?.ads_upload_type) {
          setValidationMessageads_upload_type("Please select Upload Type.");
          setIsValidationHiddenads_upload_type(false);
          focusInputRef = ads_upload_typeInputRef;
      }

      if (focusInputRef) {
          focusInputRef?.current?.focus();
          return;
      }

      const selectedAgesForRequest = selectedAges?.filter(Boolean);
      const editInputValue = {
          ads_name: editUser?.ads_name,
          ads_position: editUser?.ads_position,
          ads_upload_type: editUser?.ads_upload_type,
          ads_category: editUser?.ads_category,
          ads_redirection_url: editUser?.ads_redirection_url,
          location: editUser?.location,
          ads_path: editUser?.ads_path,
          status: editUser?.status,
          age: selectedAgesForRequest,
          gender: selectedValuesgender?.map((option) => option?.value),
          devices: selectedValuesdevices?.map((option) => option?.value),
          schedule: editUser?.schedule,
      };

      setProcessing(true);
      setShowOverlay(true);

      let firstApiStatus = false;
      let secondApiStatus = false;
      let resultApi = null;
      let resultApiError = null;

      try {
         
         const response = await axios.patch(`${process.env.REACT_APP_Baseurl}/advertiser/advertisement-update/${id}`,
             editInputValue, { headers: headers } 
         );
         localStorage.removeItem("weeklyHours");
         const responseData =response;

         if (responseData?.status) {
            firstApiStatus = true;
            resultApi = responseData;

            if (advertisement_videos_upload) {
               const formDataUpload = new FormData();
               formDataUpload.append("advertisement_videos_upload", advertisement_videos_upload);
              
               let headers = {
                   "Content-Type": "multipart/form-data",
                   headers: { "Access-Control-Allow-Origin": "*" },
                   Authorization: "Bearer " + access_token,
               };
               const videoResponse = await axios.patch( 
                 `${process.env.REACT_APP_Baseurl}/advertiser/advertisement-upload/${id}`,
                  formDataUpload, 
                  { headers: headers } 
               );

               if (videoResponse?.data?.status) {
                     secondApiStatus = true;
               }
            } else {
               secondApiStatus = true;
            }
         } else {
            resultApiError = responseData;
         }
         toast.success("Advertisement Updated Successfully");
         setTimeout(() => { navigate('/ads-list') }, 500); 
      } catch (error) {
            const errorMessage = error?.response?.data?.errors?.[0]?.msg || "Error saving data";
            console.log(error);
            // alert(errorMessage);
            toast.error( errorMessage);
      }
   };

   const [alluserads_upload_type, setAllUserads_upload_type] = useState("");
   function allads_upload_type() {
      switch (alluserads_upload_type) {
          case "tag_url":
              return <div>
                  <div className=" text-start">
                      <label className="m-0">
                          Ad Tag Url
                      </label>
                      <div className="mt-2">
                        <input
                           type="text"
                           className="rs-input form-control"
                           id="ads_path"
                           name="ads_path"
                           placeholder="Enter Ads URL"
                           onChange={handleInput}
                           value={editUser?.ads_path}
                        />
                      </div>
                  </div>
              </div>;
          case "ads_video_upload":
              return <div>
                  <div className=" text-start">
                      <label className="m-0">
                          Ads Video Upload
                      </label>
                      <div className="mt-2">
                          <input
                              type="file"
                              className="form-control rs-input"
                              name="pdf_file"
                              id="pdf_file"
                              accept="video/*"
                              onChange={(e) => setAdvertisement_videos_upload(e.target.files[0])}
                          />
                      </div>
                  </div>

                  <div className=" text-start mt-2">
                      <label className="m-0">
                          Ads Redirection URL
                      </label>
                      <div className="mt-2">
                          <input
                              type="text"
                              className="rs-input form-control"
                              id="ads_redirection_url"
                              name="ads_redirection_url"
                              placeholder="Enter Ads Redirection URL"
                              onChange={handleInput}
                              value={editUser?.ads_redirection_url}
                          />
                      </div>

                  </div>

              </div>;
          default:
              return null;
      }
   }
    
   useEffect(() => {
      fetchEditDetails();

      if (editUser?.ads_upload_type) {
         setAllUserads_upload_type(editUser?.ads_upload_type);
       }

   }, [editUser?.ads_upload_type]);

   return (
      <>
         <AdvertiserPanel>
            <div className="p-3 rounded-3">

               <div className="theme-bg-color p-3 mt-3 rounded-2 bootstrapTable multi-delete-width">
                  <div className="col-lg-12 row px-3">
                     <div className="col-6 col-sm-6 col-lg-6 p-2">
                        <AdminNavbar heading="Edit Advertisement" />
                     </div>
                     <div className="col-6 col-sm-6 col-lg-6 p-2">
                           <div className="text-end">
                              <button onClick={handleStore} className="btn btn-primary">
                                 Submit
                              </button>
                           </div>
                     </div>
                  </div>
               </div>

               <div> {showOverlay && <Response_Processing></Response_Processing>} </div>

               <div className="theme-bg-color p-3 mt-3 rounded-2 bootstrapTable multi-delete-width">
                  <div className="row col-lg-12 ">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                           <div className="card p-3">
                                <h5>{editname?.ads_name}</h5>
                                <hr></hr>
                              <div className="row mt-2 text-start">
                                 <label className="m-0">
                                       Advertisement Name<span className="mandatory">*</span>
                                 </label>
                                 <div className="mt-2">
                                       <input
                                          type="text"
                                          className="rs-input form-control"
                                          id="ads_name"
                                          name="ads_name"
                                          placeholder="Enter Name"
                                          onChange={handleInput}
                                          value={editUser?.ads_name}
                                          ref={titleInputRef}
                                       />
                                 </div>
                                 <span className="errorred">
                                       {!isValidationHiddentitle && (
                                          <p>{validationMessagetitle}</p>
                                       )}
                                 </span>
                              </div>

                              <div className="row mt-2 text-start">
                                 <div className="col-sm-12">
                                    <label className="m-0">Advertisement Upload Type<span className="mandatory">*</span></label>
                                    <select
                                       onChange={handleInput}
                                       value={editUser?.ads_upload_type}
                                       name="ads_upload_type"
                                       id="ads_upload_type"
                                       className="form-select"
                                       ref={ads_upload_typeInputRef}
                                       onClick={(event) => {
                                          setAllUserads_upload_type(event.target.value);
                                       }}
                                    >
                                       {user_ads_upload_type?.map((item, key) => (
                                          <option key={key} value={item?.value}>
                                                {item?.name}
                                          </option>
                                       ))}
                                    </select>

                                 </div>
                                 <div className='mt-2'>{allads_upload_type()}</div>

                                 <span className="errorred mt-2 d-block">
                                       {!isValidationHiddenads_upload_type && (
                                          <p>{validationMessageuser_ads_upload_type}</p>
                                       )}
                                 </span>
                              </div>

                              {/* <div className="text-start">
                                 <div>
                                    <div className="col-12 mt-2">
                                       <label className="m-0">Age</label>
                                       <div className="d-flex align-items-center">
                                          {apiResponseage?.map((age) => (
                                                <div key={age?.value}>
                                                   <label className='age-label px-1'>
                                                      <input
                                                         type="checkbox"
                                                         value={age?.value}
                                                         checked={selectedAges.includes(age?.value)}
                                                         onChange={() => handleCheckboxChange(age?.value)}
                                                         className="checkbox"
                                                      />
                                                      {age?.name}
                                                   </label>
                                                </div>
                                          ))}
                                       </div>
                                    </div>
                                 </div>
                              </div> */}
                           </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                           <div className="card p-3">
                              <h5>Category</h5>
                              <hr></hr>

                              <div className="row mt-2 text-start">
                                    <div className="col-12">
                                       <label className="m-0">Advertisement positon</label>

                                       <div className="mt-2">
                                          <select
                                             className="form-select"
                                             name="ads_position"
                                             id="ads_position"
                                             onChange={handleInput}
                                             value={editUser?.ads_position}
                                          >
                                             {ads_positionselect
                                                ?.filter(item => item.value !== 'all').map((item) => (
                                                   <option key={item?.value} value={item?.value}>{item?.name}</option>
                                                ))
                                             }
                                             {/* {ads_positionselect?.map((item) => (
                                                <option value={item?.value}>{item?.name}</option>
                                             ))} */}
                                          </select>
                                       </div>
                                    </div>
                              </div>

                              <div className="row mt-2 text-start">
                                    <div className="col-12">
                                       <label className="m-0">Advertisement Category</label>

                                       <div className="mt-2">
                                          <select
                                                className="form-select"
                                                name="ads_category"
                                                id="ads_category"
                                                onChange={handleInput}
                                                value={editUser?.ads_category}
                                          >
                                             {ads_categoryselect?.map((item) => (
                                                <option value={item?.id}>{item?.name}</option>
                                             ))}
                                          </select>
                                       </div>
                                    </div>
                              </div>

                              {/* <div className="col-12  mt-2 ">
                                    <label className="m-0">Gender</label>

                                    <div className="mt-2">
                                       <select
                                          isMulti
                                          className="form-select"
                                          id="category"
                                          onChange={handleSelectChangegender}
                                          value={selectedValuesgender}
                                       >
                                          {optionsgender?.map((item) => (
                                             <option value={item?.value}>{item?.value}</option>
                                          ))}
                                       </select>
                                    </div>
                              </div> */}

                              {/* <div className="col-12 mt-2">
                                    <label className="m-0">Devices</label>

                                    <div className="mt-2">
                                       <select
                                          isMulti
                                          className="form-select"
                                          id="devices"
                                          onChange={handleSelectChangedevices}
                                          value={selectedValuesdevices}
                                       >
                                          {optionsdevices?.map((item) => (
                                             <option value={item?.value}>{item?.value}</option>
                                          ))}
                                       </select>
                                    </div>
                              </div> */}

                           </div>
                        </div>
                  </div>
               </div>

               <div className="theme-bg-color p-3 mt-3 rounded-2 bootstrapTable multi-delete-width">
                  <div className="row col-lg-12 ">
                     <div className="col-12">
                           <AdvertisementEditTimeSlot data={transformedAdsEvents} />
                        <div className="text-start mb-3">
                           <button className="btn btn-primary" onClick={handleStore}>
                              Submit
                           </button>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </AdvertiserPanel>
      </>
   );
};

export default AdvertisementEdit;