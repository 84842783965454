import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdvertiserFooter from './AdvertiserFooter';
import AdvertiserHeader from './AdvertiserHeader';
import AdvertiserSideMenu from './AdvertiserSideMenu';
import '../../../Styles/CommonAdvertiser.css';

const AdvertiserCommonLayout = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <>
            <AdvertiserSideMenu toggleClassName={isOpen} />
            <div className="adminSideBarRight">
                <AdvertiserHeader toggleAction={toggle} />
                <Outlet />
                <AdvertiserFooter />
            </div>
        </>
    )
}

export default AdvertiserCommonLayout